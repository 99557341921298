<template>
    <div
        :class="$config('app.layoutColors.containerBackground')"
        class="fill-height align-start"
    >
        <template>
            <app-header @toggleNav="drawer = !drawer"></app-header>
            <app-sidebar :drawer.sync="drawer"></app-sidebar>
            <v-main>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <vue-page-transition name="fade-in-up">
                                <keep-alive>
                                    <router-view
                                        v-if="$route.meta.keepAlive"
                                    ></router-view>
                                </keep-alive>
                            </vue-page-transition>

                            <vue-page-transition name="fade-in-up">
                                <router-view
                                    v-if="!$route.meta.keepAlive"
                                ></router-view>
                            </vue-page-transition>
                        </v-col>
                    </v-row>
                </v-container>
            </v-main>
            <app-footer></app-footer>
            <psi-snackbar></psi-snackbar>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "auth-layout",
    components: {
        AppHeader: () => import("./AppHeader"),
        AppSidebar: () => import("./AppSidebar"),
        AppFooter: () => import("./AppFooter"),
    },
    data: () => ({
        drawer: null,
    }),
    computed: {
        ...mapGetters(["loading"]),
    },
    created() {
        // If we are prototyping, let's pull the prototype user from the API since we bypass login information
        if (process.env.VUE_APP_PROTOTYPING) {
            this.getAuthenticatedUser();
        }
    },
    mounted() {},
    methods: {
        ...mapActions("Users", ["getAuthenticatedUser"]),
    },
};
</script>

<style>
.v-navigation-drawer__border {
    display: none;
}
.slide-leave-active {
    transition: 0.1s;
    opacity: 0;
}
.slide-enter-active {
    transition: 0.9s;
    opacity: 1;
}
.slide-enter {
    transform: translateY(100%);
}
.slide-leave-to {
    transform: translateY(50%);
}
</style>

